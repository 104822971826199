import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Aligner = makeShortcode("Aligner");
const Box = makeShortcode("Box");
const InfoBlock = makeShortcode("InfoBlock");
const Button = makeShortcode("Button");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Link = makeShortcode("Link");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "🦋-serenity",
      "style": {
        "position": "relative"
      }
    }}>{`🦋 Serenity`}<a parentName="h1" {...{
        "href": "#%F0%9F%A6%8B-serenity",
        "aria-label": "🦋 serenity permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We have finally reached the tranquil waters of Ethereum 2.0 (also known as “Serenity”). It's been many years in the making, and it appears we still have some way to go yet. `}</p>
    <p>{`In fact, coming up with the right wording for the previous sentence was quite a challenge, because Kernel aims for “evergreen” content which will still be accurate and relevant at least a decade from now. As we will shortly see, this is an aspiration we share with the Ethereum 2.0 Design Rationale.`}</p>
    <p>{`You might wonder, are we saying that we will “still have some way to go” ten years from now? We certainly hope so. The desire for enclosure, for a certain and definitive end state which can be encapsulated as a “product” and consumed by the wider culture is a peculiarly modern phenomenon. `}</p>
    <p>{`We hope that Ethereum, and networks like it, are never really finished. We hope that people continue tinkering, playing with new ways of relating to one another for as long as there are world machines and people using them to build trust spaces. What is truly fascinating is not finished products to sell, but `}<strong parentName="p">{`the ongoing art of making possible`}</strong>{`:`}</p>
    <Aligner center mdxType="Aligner">
      <Box mdxType="Box">
        <p><em parentName="p">{`"And once those limits are understood`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`To understand that limitations no longer exist.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Earth could be fair. And you and I must be free`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Not to save the world in a glorious crusade`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Not to kill ourselves with a nameless gnawing pain`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`But to practice with all the skill of our being`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`The art of making possible."`}</em>{`  `}</p>
        <p>{`Nancy Scheibner `}</p>
      </Box>
    </Aligner>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Ethereum 2.0 Design Rationale`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/ethereum.png",
            "alt": "Design Rationale"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://notes.ethereum.org/@vbuterin/rkhCgQteN?type=view" mdxType="Button">
    Read
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h3 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h3" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`In a way, this is what Kernel has been preparing you for: the `}<a parentName="p" {...{
        "href": "/learn/module-1/understanding-ethereum/#kaizen-and-the-principle-of-subtraction"
      }}>{`continuous iteration`}</a>{` which leads to a global, public, decentralized and censorship-resistant computing surface which anyone can use and no-one owns. `}</p>
    <p>{`What this `}<a parentName="p" {...{
        "href": "/learn/module-1/meaning/"
      }}><em parentName="a">{`means`}</em></a>{` for our networked species remains an open `}<a parentName="p" {...{
        "href": "/learn/module-2/better-questions/"
      }}>{`question`}</a>{`. One thing seems clear: it is an upgrade the likes of which is only seen once in an `}<a parentName="p" {...{
        "href": "/learn/module-2/debt/#virtual-money-coins-and-an-ancient-global-system"
      }}>{`age`}</a>{`. You'll need to recall the arguments made in `}<a parentName="p" {...{
        "href": "/learn/module-1/value/"
      }}>{`value`}</a>{` and `}<a parentName="p" {...{
        "href": "/learn/module-5/incentives"
      }}>{`incentives`}</a>{` to understand the design rationale for these next few scenes of the `}<a parentName="p" {...{
        "href": "/learn/module-0/conversation/"
      }}>{`human`}</a>{` `}<a parentName="p" {...{
        "href": "/learn/module-5/listening-stories/"
      }}>{`story`}</a>{`.`}</p>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We’ll be taking a close look at Vitalik’s Design Rationale for Ethereum 2.0, which is the move to a Proof-of-Stake network that also greatly increases capacity by using “shards”. Don’t get intimidated by the technical jargon here: this has been included in the syllabus for two primary reasons:`}</p>
    <Process mdxType="Process">
      <p>{`Previously, when discussing mechanism design and game mechanics which arise from revealing truth with least contrivance, we made the claim that “exploration of low-level primitives yields possibility”. We need to look at the primitives, but the intention is primarily to walk away with a better understanding of the possibilities they imply.  `}</p>
      <p>{`When discussing money and speech, we claimed that - with blockchains - the best way to protect free speech is to price it correctly. We don't enforce "the good" by legal fiat and deal with exceptions like libel and hate speech through human interpretation and violent enforcement; we define what is "bad" and set a price on it such that malicious expression provably costs more than what may be gained from it. Ethereum 2.0 extends this idea greatly: it is our generation's elder game of economic penalties.`}</p>
    </Process>
    <h3 {...{
      "id": "principled-layers",
      "style": {
        "position": "relative"
      }
    }}>{`Principled Layers`}<a parentName="h3" {...{
        "href": "#principled-layers",
        "aria-label": "principled layers permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Just as `}<a parentName="p" {...{
        "href": "/start/principled-people"
      }}>{`we did for Kernel`}</a>{`, Vitalik begins Ethereum 2.0's Design Rationale with a series of core principles:`}</p>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Simplicity`}</strong>{`: given the inherent complexity of blockchain networks, simplicity `}</p>
        <ul>
          <li parentName="ul">{`minimizes development costs,  `}</li>
          <li parentName="ul">{`reduces the risk of unforeseen security issues, and  `}</li>
          <li parentName="ul">{`helps protocol designers to more easily convince users that design choices are legitimate.  `}</li>
        </ul>
      </Box>
      <p>{`Long-term `}<strong parentName="p">{`stability`}</strong>{`: the lower levels of the protocol should ideally be built so that there is no need to change them for a decade or longer.`}</p>
      <p><strong parentName="p">{`Sufficiency`}</strong>{`: it should be possible to build `}<em parentName="p">{`any class`}</em>{` of applications on top of the protocol.`}</p>
      <p><strong parentName="p">{`Defense`}</strong>{` in depth: the protocol should continue to work as well as possible under a variety of possible security assumptions (concerning network latency, fault count, the motivations of users etc.)`}</p>
      <p>{`Full light-client `}<strong parentName="p">{`verifiability`}</strong>{`: a client should be able to gain assurance that all of the data in the system is available and valid, even under a 51% attack.`}</p>
    </Process>
    <p>{`In our `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern"
      }}>{`very first note`}</a>{`, we made the point that our way of thinking and solving problems always has to do with trade-offs. The trade-off between complexity and functionality is a deep one. Ethereum developers have taken the approach of splitting the network into “layers” in order to make better trade-offs between complexity and the functionality we need to build a global computing fabric that everyone can use. `}</p>
    <p>{`The core idea is that we keep the “bottom layer” or “Layer 1” as simple as possible: it just processes critical or high-value transactions that require global agreement. This adheres to the first, second, and fourth principle above. Then, we move more complex processes–which cost more to run and may not need everyone in the world to agree on them right now–to other layers further up, often called “Layer 2”. `}</p>
    <p>{`There are many different kinds of “Layer 2” options, which make different trade-offs depending on what they are optimising. All the complexity gets moved here–and understanding all the different approaches can be daunting–but a diversity of approaches to processing different people’s different needs and use cases is required to give us all “sufficiency”, as well as providing “defence in depth” in a different kind of way.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What are the five principles of the Ethereum 2.0 Design Rationale?`}</p>
        <p>{`Simplicity, stability, sufficiency, defense, verifiability.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "a-defenders-game",
      "style": {
        "position": "relative"
      }
    }}>{`A Defender's Game`}<a parentName="h3" {...{
        "href": "#a-defenders-game",
        "aria-label": "a defenders game permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Bitcoin was a major innovation - it's almost like time-travelers came back to 2009 and dropped
it on an obscure mailing list for us to puzzle over. However, `}<Link to="https://www.gwern.net/Bitcoin-is-Worse-is-Better" mdxType="Link">{`it is not elegant`}</Link>{`
and its consensus algorithm breaks one of the fundamental advantages cryptography provides:
`}<strong parentName="p">{`adversarial conflict should heavily favor defenders`}</strong>{`. Seasteads are easier to destroy than
build, but an average person’s keys are secure enough to resist even state-level actors.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Systems that consider themselves ideological heirs to the cypherpunk spirit should maintain
this basic property, and be much more expensive to destroy or disrupt than they are to use and
maintain.`}</p>
    </blockquote>
    <p>{`Proof of Work security can only come from block rewards, and incentives to miners can only
come from the risk of losing future block rewards. That is to say, Proof of Work necessarily operates on a
logic of massive power incentivized into existence by massive rewards. This is effective, but
inefficient. The cost of attack and the cost of defense are at a 1:1 ratio, so there is no
`}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.daoistry.com/mindsets/defenders-advantage/"
        }}>{`defender’s advantage`}</a></strong>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Proof of Stake breaks this symmetry by relying not on rewards for security, but rather
penalties `}{`[...]`}{` The “one-sentence philosophy” of Proof of Stake is thus not “security comes
from burning energy”, but rather “security comes from putting up economic value-at-loss”.`}</p>
    </blockquote>
    <p>{`Of course, the wise design of `}<a parentName="p" {...{
        "href": "/token/token-case-studies/#the-cost-of-decisions"
      }}>{`penalties can be its own reward`}</a>{`.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What kind of actor does cryptography favour fundamentally?`}</p>
        <p>{`(individual) defenders.`}</p>
      </Card>
    </Flash>
    <p>{`Consider Amazon's algorithm and how they solved unbounded search by turning everything into a
platform, only to find that `}<a parentName="p" {...{
        "href": "/learn/module-5/amazon-unbounded-search/#unsolved-advertising"
      }}>{`advertising is intractable to platform solutions`}</a>{`.
This is because the limited number of top spots on infinite-length shelves, and the crazy
amounts of revenue they generate, create a conflict of interest between Amazon and its users.
This kind of conflict can only be `}<a parentName="p" {...{
        "href": "/learn/module-0/money-language/#open-protocols-and-a-network-of-value"
      }}>{`solved with a `}<strong parentName="a">{`protocol`}</strong>{`, not a platform`}</a>{`.
However, Proof of Work protocols still have a similar conflict of interests between miners and users.
Miners must pay for the massive power they are incentivized to use in securing a Proof of Work network,
which creates both (i) consistent forced sellers in any market (miners must sell some amount of the tokens they earn as rewards in order to pay for hardware and power costs) and (ii) skewed incentives around, for instance, block sizes which lead to suboptimal outcomes for users of the network.`}</p>
    <p>{`Proof of Stake ensures that anyone, even with entry-level hardware and a relatively small amount of ETH, can act as a
validator and that the protocol relies on penalties rather than rewards. This means that users and
validators are more likely to be `}<em parentName="p">{`the same people`}</em>{`, thus reducing conflict. Just as protocols
which define and encode what it means to cheat do not need to be `}<a parentName="p" {...{
        "href": "/learn/module-0/trust/"
      }}>{`trusted`}</a>{`,
protocols which define and encode penalties are `}<strong parentName="p">{`more likely to benefit all their users`}</strong>{` than protocols which rely on rewards.
This is because encoding rewards creates inevitably skewed incentives that only accrue to the
subset of network participants who are best placed to game the system.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: The cost of attacking or defending Proof of Work consensus is 1:1. Proof of Stake breaks this symmetry by relying on what, instead of rewards? `}</p>
        <p>{`Penalties.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: This solves the conflict of interest between miners and users, because Ethereum 2.0 validators and Ethereum 2.0 users are more likely to be what?`}</p>
        <p>{`The same people. `}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "proving-stake",
      "style": {
        "position": "relative"
      }
    }}>{`Proving Stake`}<a parentName="h3" {...{
        "href": "#proving-stake",
        "aria-label": "proving stake permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Ethereum 2.0 uses a slashing mechanism where a validator that is detected to have misbehaved can be penalized: in the best case by only about 1%, but in the worst case by up to its entire security deposit. This raises the cost of an attack such that we achieve the defender's advantage described above without creating an incentive for validators to validate each transaction without checking it fully (as this is less expensive computationally).`}</p>
    <p>{`In the Ethereum 2.0 Design Rationale, Vitalik describes why "Casper the Friendly Finality Gadget" was chosen - it was the simplest Proof of Stake consensus algorithm available at the time - and how other options continue to be explored. He highlights the problem of "supernodes" and why
sharding is better: less centralization risk, more censorship resistance, and better long-term scalability. Critically, he then considers the security models of any system we choose, and how it operates not just under the "honest majority" model (where 51% of the validators are assumed to be trustworthy), but also the "uncoordinated rational majority" and "worst-case" models. Assuming the worst case, the question remains:`}</p>
    <ul>
      <li parentName="ul">{`Can we force an attacker to have to pay a very high cost to break the chain’s guarantees?`}</li>
      <li parentName="ul">{`What guarantees can we unconditionally preserve?`}</li>
    </ul>
    <p>{`Slashing ensures the first condition, and Vitalik provides a detailed table of the guarantees
we can preserve with Proof of Stake systems in `}<Link to="https://notes.ethereum.org/@vbuterin/rkhCgQteN?type=view#Security-models" mdxType="Link">{`this section`}</Link>{`.
In a system designed around penalties, you need to distinguish between various types of
validator failure - most of which are benign (like simply being offline) - and only a few of
which are genuinely malicious. Critically, `}<strong parentName="p">{`it is the trade-off between different penalties`}</strong>{`
which informs how we structure rewards.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What kind of mechanism is used to ensure that attackers still pay a very high cost to break the chain's guarantees, assuming the worst-case model?`}</p>
        <p>{`Slashing (extra points if you said Casper the Friendly Finality Gadget).`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "aligning-incentives",
      "style": {
        "position": "relative"
      }
    }}>{`Aligning Incentives`}<a parentName="h3" {...{
        "href": "#aligning-incentives",
        "aria-label": "aligning incentives permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`In Ethereum 2.0, validators must attest to what they believe to be the head of the chain in each epoch, i.e. approximately every 6.5 minutes. If they
do so, they earn a `}<strong parentName="p">{`base reward`}</strong>{`, which is split into 5 parts described `}<Link to="https://notes.ethereum.org/@vbuterin/rkhCgQteN?type=view#Base-rewards" mdxType="Link">{`here`}</Link>{`. Rather than detailing all of these, we’ll focus here on two critical features of the reward: how it prevents "`}<Link to="https://raw.githubusercontent.com/ethereum/research/master/papers/discouragement/discouragement.pdf" mdxType="Link">{`griefing`}</Link>{`", and how it is calculated. `}</p>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <p>{`Griefing occurs when an attacker seeks to reduce other validators’ revenue, even at a
cost to themselves, in order to encourage the victims to drop out of the mechanism (either
so the attacker can get more revenue, or as part of a longer-term 51% attack). `}</p>
        <p>{`By awarding any compliant validator with an amount corresponding to the base reward `}<strong parentName="p">{`B`}</strong>{` multiplied by `}<strong parentName="p">{`P`}</strong>{` (the portion of validators that agree) and penalising any validator who doesn't with `}<strong parentName="p">{`−B`}</strong>{`, we implement a collective rewards scheme where “if anyone performs better, everyone performs better”. This bounds the griefing factors in an optimal way and is the best example of `}<a parentName="p" {...{
            "href": "/learn/module-5/prosocial-value"
          }}>{`explicitly prosocial mechanism design`}</a>{` we know of in any blockchain.`}</p>
      </Box>
      <p>{`The `}<strong parentName="p">{`base reward`}</strong>{` is proportional to the inverse square root of all security deposits made by the validators of the system. This strikes a
compromise between a `}<em parentName="p">{`fixed reward rate`}</em>{` and a `}<em parentName="p">{`fixed total reward`}</em>{`. The first creates too much
uncertainty about both issuance and the total level of deposits; the second potentially
incentivizes griefing more than can be disincentivized by the collective scheme above. Again,
mechanism design is `}<em parentName="p">{`all about balancing trade-offs`}</em>{`.`}</p>
    </Process>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Multiplying the base reward `}<strong parentName="p">{`B`}</strong>{` with the portion of validators who agree, while only penalizing those who disagree with `}<strong parentName="p">{`-B`}</strong>{` is what kind explicitly prosocial scheme?`}</p>
        <p>{`Collective rewards.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Making the base reward proportional to the inverse square root of all deposits strikes a balance between what two fixed quantities?`}</p>
        <p>{`Reward rate and total rewards.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: If the reward rate were fixed, it would create too much uncertainty about what two things?`}</p>
        <p>{`Total issuance and total deposits (i.e. how much ETH is created over time, and how much we can reasonably expect to be staked).`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: If the total rewards were fixed, it would incentivize what kind of malicious behaviour?`}</p>
        <p>{`Griefing.`}</p>
      </Card>
    </Flash>
    <p>{`Rewards are designed this way `}<em parentName="p">{`only as a result`}</em>{` of thinking about how to penalize
different kinds of undesirable validator behaviour. Now that we understand this premise, we
can check that the rewards fit our requirements by considering the `}<strong parentName="p">{`break-even uptime`}</strong>{` for
any validator. It turns out that if everyone else is validating, you need only be online ~44.44% of the time. However,
if other validators are offline - say P = 2/3 - then you need to be online ~53.6% of the time.`}</p>
    <p>{`The incentives ensure that, as more validators go offline, the penalty for doing so is greater,
which creates something Vitalik calls an `}<strong parentName="p">{`inactivity leak`}</strong>{`. If the chain fails to finalize
for more than 4 epochs, a second penalty component is added which increases quadratically over
time. This:`}</p>
    <ul>
      <li parentName="ul">{`Penalizes being offline much more heavily in the case where you being offline is actually
preventing blocks from being finalized and`}</li>
      <li parentName="ul">{`Ensures that if more than 1/3 do go offline, eventually the portion online goes back up to
2/3 because of the declining deposits of the offline validators.`}</li>
    </ul>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: If everyone else is online, what percentage of time must I be online for to break even as a validator?`}</p>
        <p>{`~44.4%. `}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: If others start to go offline, does the break even uptime for validators increase or decrease?`}</p>
        <p>{`Increase (as there is a higher penalty if my being offline impacts the chain's ability to reach finality).`}</p>
      </Card>
    </Flash>
    <p>{`All of this means that we can handle elegantly the penalties for common and benign kinds of validator failures. But what about actual attacks and/or malicious behaviour? If a validator is caught violating
the Casper FFG slashing condition, `}<Link to="https://github.com/ethereum/eth2.0-specs/blob/dev/specs/phase0/beacon-chain.md#proposer-slashings" mdxType="Link">{`they get penalized`}</Link>{` by a portion of their deposit equal `}<Link to="https://github.com/ethereum/eth2.0-specs/blob/dev/specs/phase0/beacon-chain.md#slashings" mdxType="Link">{`to three times`}</Link>{` the portion of validators that were penalized around the same time. The reasoning behind this is:`}</p>
    <ul>
      <li parentName="ul">{`A validator misbehaving is only really bad for the network if they misbehave at the same time
as many other validators, so it makes sense to punish them more in that case.`}</li>
      <li parentName="ul">{`It heavily penalizes actual attacks, but applies very light penalties to single isolated
failures that are likely to be honest mistakes.`}</li>
      <li parentName="ul">{`It ensures that single validators take on less risk than larger services (in the normal
case, a service running several validators would be the only one failing at the same time as themselves).`}</li>
      <li parentName="ul">{`It creates a disincentive against everyone joining one single validator pool.`}</li>
    </ul>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: True or false: the collective reward scheme of `}<strong parentName="p">{`B * P`}</strong>{` incentivises validators to pool their resources?`}</p>
        <p>{`False! We can now program collective rewards without sacrificing decentralization.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "technicalities",
      "style": {
        "position": "relative"
      }
    }}>{`Technicalities`}<a parentName="h3" {...{
        "href": "#technicalities",
        "aria-label": "technicalities permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Vitalik then discusses some of the technical choices favored in the design, like BLS signatures (which are easy to aggregate) and SSZ (a simpler serialization suite which aligns with the principle of `}<strong parentName="p">{`simplicity`}</strong>{`). If you're interested, we recommend going directly to the Rationale for these details. `}<a parentName="p" {...{
        "href": "https://twitter.com/LuozhuZhang/status/1579845478920749060"
      }}>{`This twitter thread`}</a>{` does an excellent job of providing an overview of the development of Ethereum 2.0 consensus and the associated research if you want more history and detail than can be found in the Design Rationale itself.`}</p>
    <h3 {...{
      "id": "the-positive-sum",
      "style": {
        "position": "relative"
      }
    }}>{`The Positive Sum`}<a parentName="h3" {...{
        "href": "#the-positive-sum",
        "aria-label": "the positive sum permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`What is Ethereum 2.0? Well, we said it already: our generation's elder game of economic penalties. These penalties are the game mechanics we use to reveal a `}<a parentName="p" {...{
        "href": "/learn/module-5/reveal-the-universe/#the-value-of-truth"
      }}>{`unique kind of truth`}</a>{`: it is possible to build - and asymmetrically defend and maintain - an explicitly prosocial, global, and ownerless system that provably benefits all the people who choose to use it. The benefits of encoding penalties extend to all layers of this game, including our ability to `}<Link to="https://twitter.com/VitalikButerin/status/1294461236680130560" mdxType="Link">{`use coordination costs to our advantage`}</Link>{`.`}</p>
    <p>{`Welcome. Most of us are `}<a parentName="p" {...{
        "href": "/learn/module-5/prosocial-value/#friendship"
      }}>{`friends`}</a>{` here. The alternatives are more expensive.`}</p>
    <h3 {...{
      "id": "further-resources",
      "style": {
        "position": "relative"
      }
    }}>{`Further resources`}<a parentName="h3" {...{
        "href": "#further-resources",
        "aria-label": "further resources permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`These few links are intended for those who wish to understand more technicalities and begin developing on, or building tools for, Ethereum 2.0.`}</p>
    <List mdxType="List">
      <Link to="https://github.com/ethereum/eth2.0-specs" mdxType="Link">
        <p>{`The Eth 2.0 Specs`}</p>
      </Link>
      <Link to="https://ethos.dev/beacon-chain/" mdxType="Link">
        <p>{`Ethos.dev`}</p>
      </Link>
      <Link to="https://ethereumstudymaster.com/courses/ethereum-2-0-studymaster-program/" mdxType="Link">
        <p>{`Ethereum 2.0 Studymaster`}</p>
      </Link>
      <Link to="https://www.reddit.com/r/ethereum/comments/o4unlp/ama_we_are_the_efs_research_team_pt_6_23_june_2021/h2oa00n/" mdxType="Link">
        <p>{`EF Research Team AMA`}</p>
      </Link>
      <Link to="https://stateful.mirror.xyz/Y1ED9RorG9OvEUXD8NBmXgYhSVhjj8H537-I2SZJkYA" mdxType="Link">
        <p>{`The Beacon Book`}</p>
      </Link>
      <Link to="https://vitalik.eth.limo/general/2021/05/23/scaling.html" mdxType="Link">
        <p>{`The Limits to Blockchain Scalability`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      